<template>
    <div>
        <template v-if="authed">
            <slot />
        </template>
    </div>
</template>

<script>
    import auth from "../auth";

    export default {
        name: "AuthedPage",
        data() {
            return {
                authed: false,
            }
        },
        mounted() {
            this.check()
        },
        methods: {
            check() {
                auth.check((success) => {

                    this.authed = success

                    if (!success) this.login()
                })
            },
            login() {
                if (this.authed) return

                this.$router.push('/login')
            }
        }
    }
</script>

<style scoped>
    .auth-container, #auth-qrcode {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .auth-container {
        height: 100vh;
        background-color: #dddddd;
    }

    #auth-qrcode {
        width: 400px;
        height: 400px;
        background-color: white;
    }
</style>