import request from "./repo/request";

let checkedResult = false;
let checked = false;
let checking = false;

const remember = (token, info) => {
    localStorage['token'] = token
    localStorage['user_info'] = JSON.stringify(info)
}

const getToken = () => {
    return localStorage['token']
}

const userInfo = () => {
    let str = localStorage['user_info']

    return str ? JSON.parse(str) : null;
}

const login = (code, callback) => {

    request.post('/collocation/auth/login', {code}, (data) => {
        if (data.code !== 0) {
            return callback(false, data.code)
        }
        let params = data.data

        let token = params.token
        let info = params.info

        remember(token, info)

        return callback(true)
    })
}

const check = (callback) => {

    let token = getToken()

    if (!token) return callback(false)

    if (checked) return callback(checkedResult)

    if (checking) {

        setTimeout(() => {
            check(callback)
        }, 300)

        return
    }

    checking = true;

    request.post('/collocation/auth/check', {token}, (data) => {

        checking = false

        if (data.code === 0) {
            remember(token, data.data.info)
        }

        callback(checkedResult = (data.code === 0));
        checked = true
    });
}

const loginOut = () => {
    delete localStorage['mini_app_id']
    delete localStorage['token']
    delete localStorage['user_info']
}

const setGender = (gender) => {
    localStorage['gender'] = gender
}

const gender = () => {
    return parseInt(localStorage['gender'])
}

const appId = (id) => {

    if (id) {
        localStorage['mini_app_id'] = id
    }

    if (id === 0) {
        delete localStorage['mini_app_id']

        return 0;
    }

    return localStorage['mini_app_id']
}

const appLength = (length) => {

    if (length) {
        localStorage['mini_app_length'] = length
    }

    if (length === 0) {
        delete localStorage['mini_app_length']

        return 0;
    }

    return localStorage['mini_app_length']
}


export default {
    login,
    check,
    getToken,
    loginOut,
    userInfo,
    setGender,
    gender,
    remember,
    appId,
    appLength
}