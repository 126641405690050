import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_API_URL


axios.interceptors.request.use(function (config) {

    let token = localStorage['token'];
    let params = config.params || config.data

    let appId = localStorage['mini_app_id'];

    if (token && !params['token']) {
        params['token'] = token
        params['_mini_app_id'] = appId
    }

    // 在发送请求之前做些什么
    return config;

}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    // 对响应数据做点什么

    return response;

}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});


const get = function (uri, data, callback, errCallback) {

    if (!callback) {
        callback = data
        data = {}
    }

    axios.get(uri, {
        params: data
    }).then((res) => {
        if (callback) {
            callback(res.data, res.status === 200)
        }
    })
    .catch((err) => errCallback(new Error(`网络错误请重试 ${err.message}`)))
}

const post = function (uri, data, callback, errCallback) {
    if (!callback) {
        callback = data
        data = {}
    }

    axios.post(uri, data).then((res) => {
        if (callback) {
            callback(res.data, res.status === 200)
        }
    })
    .catch((err) => errCallback(new Error(`网络错误请重试 ${err.message}`)))
}

export default {
    get, post
}