<template>
    <div v-if="loading">
        <div class="loading" :class="{bgw:showLoader}">
            <div class="wrapper">
                <loader v-if="showLoader"/>
            </div>
        </div>
    </div>
</template>

<script>
    import Loader from "./Loader";

    export default {
        name: "Loading",
        components: {Loader},
        props: {
            loading: Boolean,
        },
        data() {
            return {
                showLoader: false
            }
        },
        methods: {
            show() {
                this.loading = true
                this.showLoader = false

                setTimeout(() => {

                    this.showLoader = this.loading

                }, 300)

            },
            hide() {
                this.loading = false
            }
        }
    }
</script>

<style lang="less" scoped>
    .loading {
        width: 100vw;
        height: 100vh;
        position: fixed;
        z-index: 9999;
        left: 0;
        top: 0;
        background-color: transparent;

        .wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            .block {
                color: #6a72fe;
            }
        }
    }

    .bgw {
        background-color: rgba(255, 255, 255, 0.76);
    }
</style>