import Vue from "vue";
import componentLoading from "../components/Loading.vue";

const comLoading = Vue.extend(componentLoading);

const instance = new comLoading({
    el: document.createElement("div"),
});

const loading = {
    show() {
        instance.show();
        document.body.appendChild(instance.$el);
    },
    hide() {
        instance.hide();
    },
};
export default {
    install() {
        if (!Vue.$loading) {
            Vue.$loading = loading;
        }
        Vue.mixin({
            created() {
                this.$loading = Vue.$loading;
            },
        });
    },
};