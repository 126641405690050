<template>
    <div>
        <router-link to="/ast/xcx/trusteeship" class="sp-row flex flex-center" v-if="master">
            <img src="/assets/icons/sp-lb.png" alt="">
            <span>本机构正在被“{{  master.nickname }}”托管中</span>
        </router-link>
        <a-modal :footer="null" v-model="showInviteModal" class="custom-modal" :closable="false" :maskClosable="false">
            <div class="tp-invite-card" v-if="invite">
                <div class="tp-invite-card-head">
                    <div class="tp-title">合作邀请</div>
                    <div class="tp-sub-title">点击下方“同意”按钮即立刻建立托管关系</div>
                    <div @click="close" class="modal-close"></div>
                </div>
                <div class="tp-invite-card-body flex flex-center">
                    <img class="inv-avatar" :src="invite.avatar" alt="">
                    <div class="inv-name">{{ invite.nickname }}</div>
                    <div class="inv-tips flex flex-center">
                        <img class="inv-icon" src="/assets/images/inv-icon.png" alt="">
                        <span>对方向你发出了合作邀请</span>
                    </div>
                    <div class="inv-buttons flex flex-center">
                        <button @click="reject" class="btn btn-white">拒绝</button>
                        <button @click="confirm" class="btn">同意</button>
                    </div>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<script>
import api from "@/repo/api";

export default {
    name: "Trusteeship",
    data() {
        return {
            invite: undefined,
            showInviteModal: false,
            master: undefined,
        }
    },
    mounted() {
        this.getTrusteeship()
        this.getInvite()
    },
    methods: {
        close() {
            this.showInviteModal = false
        },
        getTrusteeship() {
            api.get('/trusteeship/master', res => {
                if (res.data) this.master = res.data
            })
        },
        getInvite() {
            api.get('/trusteeship/invite', res => {
                if (res.data) {
                    this.invite = res.data
                    this.showInviteModal = true
                }
            })
        },
        confirm() {
            this.$confirm({
                title: "确定要同意吗？",
                content: "同意将立刻建立托管关系。",
                onOk: () => {
                    this.syncInfo(1)
                }
            })
        },
        reject() {
            this.$confirm({
                title: "确定要拒绝吗？",
                onOk: () => {
                    this.syncInfo(2)
                }
            })
        },
        syncInfo(status) {

            this.$loading.show()

            api.post('/trusteeship/invite-confirm', {app_id: this.invite.app_id, status}, res => {

                this.$loading.hide()

                this.$message.data(res)

                if (res.code === 0) {
                    this.showInviteModal = false

                    window.location.href = '/ast/xcx/trusteeship#tru-fw'
                }
            })
        }
    }
}
</script>

<style scoped lang="less">

.tp-invite-card {
    width: 516px;
    height: 257px;
    position: relative;
    padding-top: 101px;
}

.tp-sub-title {
    font-weight: 400;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    line-height: 16px;
    text-align: center;
    margin-top: 8px;
}

.inv-avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
}

.inv-name {
    font-weight: 400;
    font-size: 14px;
    margin-top: 16px;
    color: #333333;
    line-height: 20px;
    text-align: center;
}

.modal-close {
    width: 16px;
    height: 16px;
    background-image: url("/assets/icons/modal-close.png");
    background-size: 100%;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}

.tp-title {
    font-weight: 900;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 34px;
    text-align: center;
    margin-top: 24px;
}

.tp-invite-card-head {
    left: 24px;
    position: relative;
    top: 101px;
    width: 360px;
    height: 202px;
    background-image: url("/assets/images/sp-inv-top.png");
    background-size: 100%;
    padding-top: 1px;
}

.tp-invite-card-body {
    width: 408px;
    height: 257px;
    position: relative;
    background-image: url("/assets/images/sp-inv-bottom.png");
    background-size: 100%;
    z-index: 2;
    flex-direction: column;
}


.inv-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.inv-tips {
    background: #CCCCCC;
    border-radius: 2px 2px 2px 2px;
    width: 194px;
    height: 20px;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 20px;
    margin-top: 25px;
}

.btn-white {
    margin-right: 16px;
}

.inv-buttons {
    margin-top: 24px;
}

.sp-row {
    height: 24px;
    background: #7F7F7F;
    border-radius: 24px;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    line-height: 24px;
    padding-left: 8px;
    padding-right: 8px;

    img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
    }
}


</style>